import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
import { FileWithFlippedCornerIcon } from '@smallstep/step-ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`What happens when a user types `}<inlineCode parentName="h2">{`ssh [host-name]`}</inlineCode></h2>
    <h3>{`First-time configuration`}</h3>
    <p>{`Users on macOS, Linux, and Windows 10 machines use `}<inlineCode parentName="p">{`step`}</inlineCode>{` to configure the local OpenSSH agent to work with SSH certificates. Smallstep does not replace OpenSSH or delete existing configurations. During setup, we add a small SSH config block that only applies to Smallstep-managed hosts, ensuring continued operations with existing key-based solutions. Specifically, we add a `}<inlineCode parentName="p">{`ProxyCommand`}</inlineCode>{` directive that checks to see if the user’s certificate needs to be renewed. If so, we'll inject the OAuth SSO flow into the ssh client flow.`}</p>
    <p>{`Let's onboard a new user named Alice. She starts by running `}<inlineCode parentName="p">{`step ssh config --team [team-name]`}</inlineCode>{`. She goes through an in-browser login flow with the company's identity provider (IdP), and she's ready to go.`}</p>
    <h3>{`Daily use in detail`}</h3>
    <p>{`Our step tool works alongside OpenSSH—we don't replace OpenSSH. Each workday, when Alice first runs `}<inlineCode parentName="p">{`ssh [hostname]`}</inlineCode>{`, we check in with the Smallstep CA to see if the host she's connecting to is managed in Smallstep. If so, `}<inlineCode parentName="p">{`step`}</inlineCode>{` makes sure Alice's SSH certificate is valid. Since this is her first session of the day, she needs a new certificate. Alice signs in to your Identity Provider, which returns an OIDC bearer token to the login utility. `}<inlineCode parentName="p">{`step`}</inlineCode>{` exchanges this token for a signed SSH certificate from Smallstep CA.`}</p>
    <p>{`The certificate is valid for 16 hours by default, and it includes a list of Principals that Alice identifies as for SSH (for example, `}<inlineCode parentName="p">{`alice`}</inlineCode>{`, and `}<inlineCode parentName="p">{`alice@mycompany.com`}</inlineCode>{`). If Alice is using our GitHub integration, her GitHub username is also attached to the certificate, and she can use it to push code there. (`}<a parentName="p" {...{
        "href": "https://github.com/enterprise"
      }}>{`GitHub Enterprise`}</a>{` required)`}</p>
    <p>{`Finally, `}<inlineCode parentName="p">{`step`}</inlineCode>{`stores Alice's certificate in her laptop's SSH agent. It's never stored on disk.`}</p>
    <p>{`From here, the `}<inlineCode parentName="p">{`ssh handshake`}</inlineCode>{` continues seamlessly. `}<br /></p>
    <p><img parentName="p" {...{
        "src": "/graphics/ssh-hiw-alice1.png",
        "alt": "image",
        "title": "SSH Certificate Issued"
      }}></img></p>
    <br />
    <p>{`If Alice wants to connect from a different device, it's easier for her to run `}<inlineCode parentName="p">{`step ssh login`}</inlineCode>{` (or let `}<inlineCode parentName="p">{`step ssh proxycommand`}</inlineCode>{` run it for her transparently) than it is to extract her certificate from `}<inlineCode parentName="p">{`ssh-agent`}</inlineCode>{` and copy it over. Bastion hosts are supported, too! You can ssh to the server using its internal DNS name, and the connection will flow through your bastion. All connection requests to the host are logged and reported in the smallstep dashboard.`}</p>
    <h3>{`Manage SSH Access From Your Identity Provider`}</h3>
    <p>{`All user and group information is maintained in your existing identity provider. We pull basic information from your IdP using the SCIM protocol or native APIs. In the smallstep dashboard, you'll tag groups that need SSH access. When you add or remove users in one of these groups in your IdP, it will automatically flow to your entire managed fleet of hosts. No need to kick off automation flows to push key changes. Access is instantly added, changed, or revoked.`}</p>
    <h3>{`Standard, Secure Connections`}</h3>
    <p>{`Smallstep integrates with your identity provider using SCIM for user provisioning and OIDC for authentication. Users and hosts are issued both SSH and X.509 certificates (for securing interaction with smallstep's APIs using mTLS). The client API is used to determine if a host is managed, to discover bastions, and for other service-related requests.`}</p>
    <h3>{`First-time Host configuration`}</h3>
    <p>{`Operators run `}<inlineCode parentName="p">{`step ca bootstrap`}</inlineCode>{` and `}<inlineCode parentName="p">{`step ssh config --host`}</inlineCode>{` to set up mTLS and to configure sshd to use certificates. Similar to User settings, we add our information to the end of the existing sshd configuration, ensuring continued operations with existing key-based solutions. This operation also instantiates the step-ssh agent, which is configured automatically to run via systemd. At setup, native Linux NSS and PAM modules are configured by the step-ssh agent. At runtime, the agent connects to the smallstep API using mTLS and is responsible for synchronizing User and group ACLs to the Host.`}</p>
    <p>{`With an SSH certificate in place, smallstep interacts with SSHD, PAM, and NSS UNIX services to enable access control, user lifecycle management, and detailed event logging. Smallstep provides the ability to automatically sync GID and UID values from your identity provider for each User. If not available, the application will automatically assign values.`}</p>
    <h3>{`Host Tags and ACLs`}</h3>
    <p>{`Host tags are similar to EC2 Instance Tags. They’re used to define collections of hosts for access control. You might assign tags identifying a host’s role (e.g., bastion, web, database), region (e.g., us-east), environment (e.g., staging, production), or any other relevant attribute. Let's look at an example:`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/acl-tag-example.png",
        "alt": null,
        "title": "Host tags example"
      }}></img></p>
    <ul>
      <li parentName="ul">{`A host tag combination of `}<inlineCode parentName="li">{`db`}</inlineCode>{`: `}<inlineCode parentName="li">{`dev`}</inlineCode>{` gives you access to `}<inlineCode parentName="li">{`myserver #1`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`A host tag combination of `}<inlineCode parentName="li">{`db`}</inlineCode>{`: `}<inlineCode parentName="li">{`prod`}</inlineCode>{` gives you access to `}<inlineCode parentName="li">{`myserver #2`}</inlineCode>{` and `}<inlineCode parentName="li">{`myserver #3`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`Host tags are used along with IdP user groups in grants. Grants give users in a particular identity provider group, access to hosts with a specific tag combination.`}</p>
    <h3>{`Access Control`}</h3>
    <p>{`With User information synced from the identity provider and the Hosts registered, the smallstep administrator can configure access control. IdP User groups are mapped to Host Tags and given standard SSH access or root access via sudo. Access grants synchronize automatically to all Managed Hosts, and the corresponding NSS and PAM modules are updated.`}</p>
    <h3>{`SSH Handshake and Host ACL Enforcement`}</h3>
    <p>{`Upon connection, `}<inlineCode parentName="p">{`ssh`}</inlineCode>{` and `}<inlineCode parentName="p">{`sshd`}</inlineCode>{` validate the authenticity of certificates using a standard exchange process:`}</p>
    <br />
    <p><img parentName="p" {...{
        "src": "/graphics/ssh-hiw-alice2.png",
        "alt": "image2",
        "title": "SSH Handshake"
      }}></img>{` `}<br /></p>
    <p>{`Upon verification of the User's certificate, the system will determine access using the following workflow:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Does the User have a local account:`}</strong>{` Invokes the NSS module and connects to the step-ssh agent to determine if the User is allowed to access the machine. If the User is listed, the system passes the valid `}<em parentName="li">{`UID, GID`}</em>{`, and `}<em parentName="li">{`user name`}</em>{`. If the User is not listed, access is denied.`}</li>
      <li parentName="ol"><strong parentName="li">{`Determine if the principal is allowed to SSH as user name:`}</strong>{` The system compares the principal list in the User's SSH certificate with the `}<em parentName="li">{`user name`}</em>{` provided by the NSS service. If they match (or if the User is an SSH authorized principal), the principal is allowed to SSH as `}<em parentName="li">{`user name`}</em>{`.`}</li>
      <li parentName="ol"><strong parentName="li">{`Determine if the user name is allowed to SSH:`}</strong>{` Invokes a PAM module to validate what permissions (SSH, Sudo, None) user name has for SSH. If SSH or Sudo, SSH access is enabled; otherwise, access is denied.`}</li>
    </ol>
    <p>{`Once user access privileges are determined, the system will:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Create a home directory if none exists.`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`Audit Logging:`}</strong>{` SSH events trigger a PAM module to log session start, sudo actions, and session end activities. This event information is displayed in the smallstep UI.`}</li>
    </ol>
    <p>{`With access verified and the proper services in place, SSH access is granted, and the User can perform any needed activities on the Host.`}</p>
    <h2>{`Ongoing Operations`}</h2>
    <p>{`Smallstep SSH is designed for environments that are continually changing. We automatically synchronize Users and Groups with your company directory. As an administrator, when you add or remove users in your company directory, SSH access is instantly granted or revoked on all your hosts. Changes to Hosts are simple commands that can be integrated into existing run books or automation systems. Smallstep administrators can also view session logging, Host lists and tags, User and user group lists, and configure billing information.`}</p>
    <h2>{`Secure and Highly Available`}</h2>
    <h3>{`Smallstep SSH Professional`}</h3>
    <p>{`The Smallstep SSH SaaS offering includes a hosted certificate authority and a set of related services to enable single sign-on SSH. The smallstep application is engineered for high availability and delivered as a managed offering running on Google Cloud. We also follow industry best practices around redundancy and backups to ensure continued operations. Certificate-based authentication relies on a trusted certificate authority. CAs use a private key to sign certificates. If a CA private key falls into the wrong hands, it can be used maliciously to impersonate people, services, or machines. Smallstep uses the Google Cloud KMS service. Designed to manage private keys, KMS prevents your private key from being transferred, so it cannot be exfiltrated and used maliciously. Instead, the KMS offers a limited API that you can use for signing. Requests to this API are logged. So you always know that your private keys are secure in the KMS. Learn more about our `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/security"
      }}>{`security measures`}</a>{`.`}</p>
    <h3>{`Smallstep SSH Run Anywhere`}</h3>
    <p>{`The same offering is available in an on-premise configuration that brings the simplicity of a SaaS offering with a private deployment's control and security. Smallstep SSH Run Anywhere installs in Kubernetes and integrates with your existing HSM for storage of private keys. `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/pricing-question"
      }}>{`Contact Us`}</a>{` for more information.`}</p>
    <h2>{`Setup and Configuration`}</h2>
    <h3>{`User`}</h3>
    <ol>
      <li parentName="ol">{`Type `}<inlineCode parentName="li">{`brew install step`}</inlineCode></li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`step ssh config --team [team-name]`}</inlineCode></li>
      <li parentName="ol">{`Go through an in-browser single sign-on OAuth OIDC flow to authenticate SSH as normal. When your certificate expires, you’ll authenticate through the browser again.`}</li>
    </ol>
    <h3>{`Identity Provider`}</h3>
    <ol>
      <li parentName="ol">{`Configure the smallstep application in your Okta, Azure AD, or G Suite identity provider interface.`}
        <ol parentName="li">
          <li parentName="ol">{`Activate OIDC flow for single sign-on workflows`}</li>
          <li parentName="ol">{`Activate SCIM to synchronize user groups`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Assign users to SSH groups (or repurpose existing groups)`}</li>
      <li parentName="ol">{`Users and groups from your identity provider will display in the smallstep UI for access control assignments.`}</li>
    </ol>
    <h3>{`Hosts`}</h3>
    <ol>
      <li parentName="ol">{`Download and install package that includes `}<inlineCode parentName="li">{`step`}</inlineCode>{` and the step-ssh agent.`}</li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`step ca bootstrap`}</inlineCode>{` and `}<inlineCode parentName="li">{`step ssh config --host`}</inlineCode>{` to set up mTLS and to configure SSH to use certificates.`}</li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`step-ssh activate`}</inlineCode>{` to start up the smallstep agent and enable the PAM and NSS modules`}</li>
      <li parentName="ol">{`Use `}<inlineCode parentName="li">{`step-ssh-ctl`}</inlineCode>{` to register the Host, define the host tags, and set the bastion flag. Host setup can be automated using Ansible, Chef, Puppet, Terraform, or any scripting language.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      